<template>
  <layout-main>
    <send-mail-basic v-if="sendMail" @sendMail="doSendMail" @closed="closeSendMail"></send-mail-basic>
    
    <message-from-a-p-i @closed="messagesFromApi = false" :messages="messagesFromApi"
                        v-if="messagesFromApi"></message-from-a-p-i>
    <select-local-office v-if="selectLocalOffice" @closed="closeSelectOffice"></select-local-office>

    <modal name="show-court-date-modal" @closed="closeCaseDateModal" :resizable="true" :scrollable="true" :width="520" :height="600">
      <h4>Datum för rättegång</h4>

      <div class="container">
          <div class="row bt-1">
            <div class="col-12 pt-3 mb-3">
              <date-field
                v-if="ticket.is_anonymized !== 1"
                :model="ticket"
                api="updateTicketField"
                field="case_date"
                :label="this.$t('labels.case-date')"
                @fieldSaved="$modal.hide('show-court-date-modal')"
                :infoBox="'Ärendeformuläret - flik Geografi, rubrik Rättegångsdatum'"
              ></date-field>
          </div>
        </div>
      </div>
    </modal>

    <div class="sidebar" v-if="showSidebar">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12 bb-1 py-3 text-uppercase">
            <h3 class="mb-0"><i class="fas fa-circle-notch fa-spin mr-2" v-show="isSaving"></i>{{ edit.title }}<small>Välj
              nytt värde</small></h3>
          </div>
        </div>
        <div class="row">
          <div class="col-12 bb-1 py-3 text-uppercase">
            <input type="text" v-on:keyup.enter="$event.target.blur()" v-model="searchFieldOptions" class="form-control"
                   placeholder="Sök.."/>
          </div>
        </div>
        <div class="row">
          <div
              class="col-12 bb-1 br-1 cursor-pointer field-option"
              v-for="data in filteredFieldOptions(edit.value)"
              :key="data.id"
              @click="setField(data)"
          >
            <div class="row" :class="{ selected: edit.selectedValues.includes(data.id), multiple: edit.multiple }">
              <div class="col-11 py-3 label-col">
                {{ data[edit.value] }}
              </div>
              <div class="col-1 py-3 icon-col">
                <i class="fas fa-check-circle"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="overlay" v-if="showSidebar" @click="toggleSidebar()"></div>
    <div class="survey">
      <!-- Header -->

      <div class="subheader">
        <div class="subheader-main">
          <div class="subheader-left">
            <h3>{{ $t('labels.ticket') }} #{{ ticket.id }}</h3>
            <span class="separator-line"></span>
            <div class="breadcrumbs">
              <router-link to="/" class="breadcrumbs-link"> {{ $t('labels.home') }}</router-link>
              <span class="separator-dot"></span>
              <router-link to="/" class="breadcrumbs-link"> {{ $t('labels.ticket') }}</router-link>
              <span class="separator-dot"></span>
              <router-link to="/" class="breadcrumbs-link"> {{ $t('labels.actively') }}</router-link>
            </div>
          </div>
          <div class="subheader-right" v-if="!isLoading">
            <v-guard :permissions="['ticket-new-from-ticket']">
              <div class="item">
                <button @click="newTicketFromTicket" v-if="ticket.is_anonymized !== 1" class="btn btn-sm btn-light">
                  <i class="fas fa-plus"></i> {{ $t('labels.ticket-same-person') }}
                </button>
              </div>
            </v-guard>

            <v-guard :permissions="['ticket-mail-office']">
              <div class="item">
                <button @click="sendTicket" v-if="ticket.is_anonymized !== 1" class="btn btn-sm btn-light">
                  <i class="fas fa-envelope"></i> {{ $t('labels.communicate-remind-on-call') }}
                </button>
              </div>
            </v-guard>

            <v-guard :permissions="['ticket-mail-office']">
                <div class="item">
                  <button @click="sendTicketUpdateNotification" v-if="ticket.is_anonymized !== 1"
                          class="btn btn-sm btn-light">
                    <i class="fas fa-envelope"></i> {{ $t('labels.update-on-call') }}
                  </button>
                </div>
              </v-guard>

            <v-guard :permissions="['ticket-mail-notification']" v-if="ticket.users.length !== 0">
              <div class="item">
                <button @click="sendTicketNotification" v-if="ticket.is_anonymized !== 1 && ticket.users.length !== 0"
                        class="btn btn-sm btn-light">
                  <i class="fas fa-envelope"></i> {{ $t('labels.notify-administrator') }}
                </button>
              </div>
            </v-guard>

            <v-guard :permissions="['ticket-spes-ping']">
              <div class="item">
                <button @click="sendTicketSpesPing" v-if="ticket.is_anonymized !== 1"
                        class="btn btn-sm btn-light">
                  <i class="fas fa-envelope"></i> {{ $t('labels.ping-spes') }}
                </button>
              </div>
            </v-guard>

            <v-guard :permissions="['ticket-office-ping']">
                <div class="item">
                  <button @click="sendTicketOfficePing" v-if="ticket.is_anonymized !== 1"
                          class="btn btn-sm btn-light">
                    <i class="fas fa-envelope"></i> {{ $t('labels.ping-office') }}
                  </button>
                </div>
            </v-guard>

            <v-guard :permissions="['ticket-view-formedla_externt']">
                <div class="item">
                  <button @click="openSendMail" data-old-click="sendExternalEmail"
                          class="btn btn-sm btn-light">
                    <i class="fas fa-envelope"></i> Förmedla externt
                  </button>
                </div>
            </v-guard>

            <div class="item">
              <div
                  v-if="ticket.is_anonymized !== 1 && $store.getters.permissions.includes('ticket-field-status-edit')"
                  class="verified label clickable"
                  :style="{ borderColor: ticket.ticketstatus.color + '1A', backgroundColor: ticket.ticketstatus.color + '1A' }"
                  @click="editField('ticketstatus_id', 'getStatuses', false, 'label', 'Status')"
              >
                <label :style="{ color: ticket.ticketstatus.color }">{{ $to(ticket.ticketstatus,'label') }}</label
                ><i :style="{ color: ticket.ticketstatus.color }" :class="ticket.ticketstatus.icon" class="ml-2"></i>
              </div>
              <div
                  v-if="ticket.is_anonymized === 1 || !$store.getters.permissions.includes('ticket-field-status-edit')"
                  class="verified label"
                  :style="{ borderColor: ticket.ticketstatus.color + '1A', backgroundColor: ticket.ticketstatus.color + '1A' }"
              >
                <label :style="{ color: ticket.ticketstatus.color }">{{ ticket.ticketstatus.label }}</label
                ><i :style="{ color: ticket.ticketstatus.color }" :class="ticket.ticketstatus.icon" class="ml-2"></i>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Header -->

      <div class="anonymized-ticket" v-if="!isLoading && ticket.is_anonymized === 1">{{ $t('labels.anonymized-ticket') }}
      </div>
      <div class="widget overview" v-if="!isLoading" :class="{ 
        'high-risk': isRisk, 
        'consent-interview': ticket.consent_fields && ticket.consent_fields.find((consent) => consent.tag == 'interview' && consent.checked == 1) 
      }">
        <div class="widget-body pr-0 pl-3">
          <div class="container-fluid">
            <div class="row">
              <div class="col-xs-12 col-md-3">
                <div class="overview-main">
                  <div class="kt-profile__main-info">
                    <div class="overview-main-title">{{ ticket.person.first_name }} {{ ticket.person.last_name }}</div>
                    <div class="overview-main-subtitle">
											<span>
												<span>{{ $to(ticket.gender,'label') }}</span> </span
                      >,
                      <span class="d-inline">
												<span v-if="ticket.age">{{ $to(ticket.age,'label') }}</span>
												<span v-if="!ticket.age">{{ $t('labels.missing-data') }}</span>
											</span>
                    </div>

                    <div v-if="ticket.is_anonymized === 1">
                      <div class="label label-xs text-uppercase mt-3 fw-600" :class="'prio-' + ticket.priority.label">
                        {{ ticket.priority.label }}
                        {{ $t('labels.prio') }}
                      </div>
                      <div
                          class="label label-xs text-uppercase mt-3 fw-600 ml-3"
                          :class="{ ' label-grey': !isRisk, 'label-red': isRisk }"
                      >
                        <i class="fas fa-exclamation-triangle"></i>
                      </div>
                    </div>
                    <div v-if="ticket.is_anonymized !== 1">
                      <v-guard :permissions="['ticket-field-priority-edit']">
                        <div
                            @click="editField('priority_id', 'getPriority', false, 'label', 'Prioritet')"
                            class="label label-xs text-uppercase mt-3 fw-600 cursor-pointer"
                            :class="'prio-' + ticket.priority.label"
                        >
                          {{ $to(ticket.priority,'label') }} {{ $t('labels.prio') }}
                        </div>
                      </v-guard>
                      <v-guard :permissions="['ticket-field-risk-edit']">
                        <div
                            v-tooltip="isRisk ? this.$t('labels.deselect-as-high-risk') : this.$t('labels.select-as-high-risk')"
                            class="label clickable label-xs text-uppercase mt-3 fw-600 ml-3"
                            @click="toggleRisk()"
                            :class="{ ' label-grey': !isRisk, 'label-red': isRisk }"
                        >
                          <i class="fas fa-exclamation-triangle"></i>
                        </div>
                      </v-guard>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xs-12 col-md">
                <div class="list overview-middle">
                  <div class="list-item">
                    <label>{{ $t('labels.crime') }}</label>
                    <span v-if="ticket.crime">{{ $to(ticket.crime.crime,'label') }}</span>
                    <span v-if="!ticket.crime">-</span>
                  </div>

                  <div class="list-item">
                    <label>{{ $t('labels.special-circumstances') }}</label>
                    <span class="d-inline" v-for="cr in ticket.crimerelation" :key="cr.id">{{ $to(cr,'label') }}, </span>
                  </div>
                </div>
              </div>
              <div class="col-xs-12 col-md">
                <div class="list overview-middle">
                  <div class="list-item">
                    <label>{{ $t('labels.category') }}</label>
                    <span v-if="ticket.category">{{ $to(ticket.category,'label') }}</span>
                    <span v-if="!ticket.category">-</span>
                  </div>
                  <div class="list-item">
                    <label>{{ $t('labels.language') }}</label>
                    <span v-if="ticket.language">{{ $to(ticket.language,'label') }}</span>
                    <span v-if="!ticket.language">-</span>
                  </div>
                </div>
              </div>
              <div class="col-xs-12 col-md">
                <div class="list overview-middle">
                  <div class="list-item">
                    <label>{{ $t('labels.victim-support-centre') }}</label>
                    <span>{{ ticket.local_office.office.label }}</span>
                  </div>
                  <div class="list-item">
                    <label>{{ $t('labels.municipality') }}</label>
                    <span v-if="ticket.municipality">{{ ticket.municipality.label }}</span>
                  </div>
                </div>
              </div>
              <div class="col-auto col-md-2">
                <div class="list overview-last">
                  <div class="list-item">
                    <label>{{ $t('labels.local-office') }}</label>
                    <span>{{ ticket.local_office.label }}</span>
                  </div>
                  <div class="list-item">
                    <label>{{ $t('labels.date') }}</label>
                    <span>{{ ticket.date_formatted }}</span>
                  </div>
                </div>
              </div>
              <div>
                <div style="display: inline-block;">
                  <info-box-sidebar field="Ärendeformuläret - mall samtal" >
                    <template v-slot:icon><i style="position: absolute; right: 21px; top: 15px;" class="fas fa-check-circle fa-2x"></i></template>
                  </info-box-sidebar>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="widget-footer with-tabs" v-if="showDetails">
          <ul class="nav nav-tabs nav-tabs-line" role="tablist">
            <v-guard :permissions="['ticket-section-person-view']">
              <li class="nav-item section" :class="{ 'current-section': showDetailSection === 2 }">
                <div class="nav-link" @click="setShowDetailSection(2)">{{ $t('labels.about-applicant') }}</div>
              </li>
            </v-guard>
            <v-guard :permissions="['ticket-section-information-view']">
              <li class="nav-item section" :class="{ 'current-section': showDetailSection === 1 }">
                <div class="nav-link" @click="setShowDetailSection(1)">{{ $t('labels.about-ticket') }}</div>
              </li>
            </v-guard>
            <v-guard :permissions="['ticket-section-primary-crime-view']">
              <li class="nav-item section" :class="{ 'current-section': showDetailSection === 6 }">
                <div class="nav-link" @click="setShowDetailSection(6)">{{ $t('labels.primary-crime-type') }}</div>
              </li>
            </v-guard>
            <v-guard :permissions="['ticket-section-secondary-crime-view']">
              <li class="nav-item section" :class="{ 'current-section': showDetailSection === 3 }">
                <div class="nav-link" @click="setShowDetailSection(3)">{{ $t('labels.additional-crimes') }}</div>
              </li>
            </v-guard>
            <v-guard :permissions="['ticket-section-geography-view']">
              <li class="nav-item section" :class="{ 'current-section': showDetailSection === 5 }">
                <div class="nav-link" @click="setShowDetailSection(5)">{{ $t('labels.geography') }}</div>
              </li>
            </v-guard>
            <v-guard :permissions="['ticket-section-police-view']">
              <li class="nav-item section" :class="{ 'current-section': showDetailSection === 4 }">
                <div class="nav-link" @click="setShowDetailSection(4)">{{ $t('labels.police-handling') }}</div>
              </li>
            </v-guard>
            <v-guard :permissions="['ticket-section-consent-view']">
              <li class="nav-item section" :class="{ 'current-section': showDetailSection === 7 }">
                <div class="nav-link" @click="setShowDetailSection(7)">{{ $t('labels.consent') }}</div>
              </li>
            </v-guard>
            <v-guard :permissions="['ticket-section-survey-view']">
              <li class="nav-item section" :class="{ 'current-section': showDetailSection === 8 }">
                <div class="nav-link" @click="setShowDetailSection(8)">{{ $t('labels.survey') }}</div>
              </li>
            </v-guard>
            <v-guard :permissions="['ticket-section-interview-view']">
              <li class="nav-item section" :class="{ 'current-section': showDetailSection === 9 }">
                <div class="nav-link" @click="setShowDetailSection(9)">{{ $t('labels.interview') }}</div>
              </li>
            </v-guard>
            <li class="nav-item ml-auto mr-0">
              <div class="nav-link" @click="toggleDetails()">{{ $t('labels.close') }} <i class="ml-2 fas fa-angle-up"></i></div>
            </li>
          </ul>
        </div>
        <div class="widget-body bt-1-dark pr-0 pl-3" v-if="showDetails && showDetailSection === 1">
          <div class="container-fluid">
            <div class="row">
              <div class="col-12">
                <h5 class="bb-1 pb-3 mb-3" style="font-size: 1rem;">{{ $t('labels.other-about') }}</h5>

                <div class="row">
                  <div class="col-6">
                    <select-field
                        :options="ticketFieldOptions.category"
                        db_field="category_id"
                        field="category"
                        :infoBox="'Ärendeformuläret - flik Om ärendet, Kategori'"
                        :label="this.$t('labels.category')"
                        :optionLabel="this.$label()"
                        api="updateTicketField"
                        :model="ticket"
                        :editable="ticket.is_anonymized !== 1 && $store.getters.permissions.includes('ticket-section-information-edit')"
                        @fieldSaved="getTicket"
                        @fieldSavedWithData="setAutomaticFields"
                    ></select-field>

                    <select-field
                        api="updateTicketField"
                        :model="ticket"
                        :options="ticketFieldOptions.police"
                        db_field="police_report_id"
                        field="police_report"
                        :infoBox="'Ärendeformuläret - flik Om ärendet, Polisanmälan'"
                        :label="this.$t('labels.police-report')"
                        :optionLabel="this.$label()"
                        :editable="ticket.is_anonymized !== 1 && $store.getters.permissions.includes('ticket-section-information-edit')"
                        @fieldSaved="getTicket"
                    ></select-field>

                    <select-field
                        api="updateTicketField"
                        :model="ticket"
                        :options="ticketFieldOptions.first_contact"
                        db_field="first_contact_id"
                        field="first_contact"
                        :infoBox="'Ärendeformuläret - flik Om ärendet, Första kontakt'"
                        :label="this.$t('labels.first-contact')"
                        :optionLabel="this.$label()"
                        :editable="ticket.is_anonymized !== 1 && $store.getters.permissions.includes('ticket-section-information-edit')"
                        @fieldSaved="getTicket"
                    ></select-field>

                    <select-field
                        api="updateTicketField"
                        :model="ticket"
                        :options="ticketFieldOptions.channel"
                        db_field="ticket_channel_id"
                        field="ticket_channel"
                        :infoBox="'Ärendeformuläret - flik Om ärendet, Inkommet via'"
                        :label="this.$t('labels.received-via')"
                        :optionLabel="this.$label()"
                        :editable="ticket.is_anonymized !== 1 && $store.getters.permissions.includes('ticket-section-information-edit')"
                        @fieldSaved="getTicket"
                    ></select-field>

                    <select-field
                        api="updateTicketField"
                        :model="ticket"
                        :options="ticketFieldOptions.priority"
                        db_field="priority_id"
                        field="priority"
                        :label="this.$t('labels.priority')"
                        :optionLabel="this.$label()"
                        :editable="ticket.is_anonymized !== 1 && $store.getters.permissions.includes('ticket-section-information-edit')"
                        @fieldSaved="getTicket"
                    ></select-field>

                    <text-field
                        v-if="ticket.is_anonymized !== 1"
                        :model="ticket"
                        :editable="$store.getters.permissions.includes('ticket-section-information-edit')"
                        api="updateTicketField"
                        field="related_tickets"
                        :infoBox="'Ärendeformuläret - flik Om ärendet, Relaterade ärenden'"
                        :label="this.$t('labels.related-tickets')"
                        :help="this.$t('labels.related-case-numbers-in-system')"
                    ></text-field>

                    <div
                        v-if="ticket.is_anonymized !== 1 && $store.getters.permissions.includes('ticket-request-removal')">
                      <h5 class="bb-1 pb-3 mb-3 mt-5" style="font-size: 1rem;">{{ $t('labels.request-removal') }}</h5>

                      <div class="form-group row">
                        <label for="ticket_firstname" class="col-sm-4 col-form-label">{{ $t('labels.ticket-number') }}</label>
                        <div class="col-sm-8">
                          <button
                              @click="sendRemovalRequest"
                              class="position-absolute btn btn-danger"
                              style="right: 20px; top: 4px;"
                              v-if="ticket.id == confirmDelete"
                          >{{ $t('labels.send') }}
                          </button>
                          <input type="text" v-model="confirmDelete" class="form-control"/>
                          <small class="description text-muted">{{ $t('labels.send-removal-request-and-press') }} <i>{{ $t('labels.send') }}</i>.</small>
                        </div>
                      </div>
                    </div>
                    <div v-if="ticket.is_anonymized !== 1">
                      <v-guard :permissions="['ticket-access-all-tickets']">
                        <div class="form-group row">
                          <label for="ticket_firstname" class="col-sm-4 col-form-label">{{ $t('labels.anonymization-date') }}</label>
                          
                            <div class="col-sm-8 pt-2">
                              {{ ticket.predicted_anonymize_date.timestamp }}
                              <v-guard :permissions="['debug-anonymize-date']">
                                <div><small class="description text-muted">{{ ticket.predicted_anonymize_date.why }}</small></div>
                              </v-guard>
                            </div>
                        </div>
                      </v-guard>
                    </div>
                  </div>
                  <div class="col-6">
                    <select-field
                        api="updateTicketField"
                        :model="ticket"
                        :options="ticketFieldOptions.crime_relation"
                        db_field="crimerelation"
                        field="crimerelation"
                        :infoBox="'Ärendeformuläret - flik Om ärendet, Särskilda omständigheter'"
                        :label="this.$t('labels.special-circumstances')"
                        :optionLabel="this.$label()"
                        :editable="ticket.is_anonymized !== 1 && $store.getters.permissions.includes('ticket-section-information-edit')"
                        :multiple="true"
                        @fieldSavedWithData="setAutomaticFields"
                    ></select-field>

                    <select-field
                        api="updateTicketField"
                        :model="ticket"
                        :options="ticketFieldOptions.suspected_perpetrator"
                        db_field="suspected_perpetrator_id"
                        field="suspected_perpetrator"
                        :infoBox="'Ärendeformuläret - flik Om ärendet, Misstänkt GP'"
                        :label="this.$t('labels.suspected-criminal')"
                        :optionLabel="this.$label()"
                        :editable="ticket.is_anonymized !== 1 && $store.getters.permissions.includes('ticket-section-information-edit')"
                        @fieldSaved="getTicket"
                    ></select-field>

                    <select-field
                        api="updateTicketField"
                        :model="ticket"
                        :options="ticketFieldOptions.special_event"
                        db_field="special_event_id"
                        field="special_event"
                        :infoBox="'Ärendeformuläret - flik Om ärendet, Särskilda händelser'"
                        :label="this.$t('labels.special-events')"
                        :optionLabel="this.$label()"
                        :editable="ticket.is_anonymized !== 1 && $store.getters.permissions.includes('ticket-section-information-edit')"
                        @fieldSaved="getTicket"
                        :clearable="true"
                    ></select-field>

                    <checkbox
                        :editable="ticket.is_anonymized !== 1 && $store.getters.permissions.includes('ticket-section-information-edit')"
                        :model="ticket"
                        api="updateTicketField"
                        field="risk_id"
                        :label="this.$t('labels.increased-risk')"
                        text="Ja"
                    ></checkbox>

                    <checkbox
                        :editable="ticket.is_anonymized !== 1 && $store.getters.permissions.includes('ticket-section-information-edit')"
                        :model="ticket"
                        api="updateTicketField"
                        field="reported_concern"
                        :infoBox="'Ärendeformuläret - flik Om ärendet, Orosanmälan'"
                        :label="this.$t('labels.report-of-concern')"
                        text="Ja"
                    ></checkbox>

                    <text-field
                        v-if="ticket.reported_concern"
                        :editable="ticket.is_anonymized !== 1 && $store.getters.permissions.includes('ticket-section-information-edit')"
                        :model="ticket"
                        api="updateTicketField"
                        field="reported_concern_reported_at"
                        :label="`- ${this.$t('labels.date')}`"
                        type="date"
                    ></text-field>

                    <div v-if="$store.getters.permissions.includes('complement-questions') && ticket.crimerelation_tickets.find((crime) => crime.crimerelation.id == 7)">
                      <h5 class="bb-1 pb-3 mb-3 mt-5" style="font-size: 1rem;">{{ $t('labels.extra-info-profession') }} <info-box field="Ärendeformuläret - flik Om ärendet, Extra info demokratibrott" /></h5>
                      <select-field
                        api="updateCrimeRelationTicketField"
                        :model="ticket.crimerelation_tickets.find((crime) => crime.crimerelation.id == 7)"
                        :options="ticketFieldOptions.professions"
                        field="profession"
                        db_field="profession_id"
                        :label="this.$t('labels.profession-category')"
                        :optionLabel="this.$label()"
                        :noDrop="true"
                        :multiple="false"
                        :clearable="true"
                        :editable="ticket.is_anonymized !== 1 && $store.getters.permissions.includes('ticket-section-information-edit')"
                      ></select-field>
                    </div>
       

                    <div v-if="$store.getters.permissions.includes('complement-questions') && ticket.crimerelation_tickets.find((crime) => crime.crimerelation.id == 1) && ticket.consent_fields.find((consent) => consent.id == 1 && consent.checked == 1)">
                      <h5 class="bb-1 pb-3 mb-3 mt-5" style="font-size: 1rem;">{{ $t('labels.extra-info-hate-crime') }} <info-box field="Ärendeformuläret - flik Om ärendet, Extra info hatbrott" /></h5>
                      <select-field
                        api="updateCrimeRelationTicketField"
                        :model="ticket.crimerelation_tickets.find((crime) => crime.crimerelation.id == 1)"
                        :options="ticketFieldOptions.hate_crime_motives"
                        field="hate_crime_motive"
                        db_field="hate_crime_motive_id"
                        :label="this.$t('labels.hate-crime-motive')"
                        :optionLabel="this.$label()"
                        :noDrop="true"
                        :clearable="true"
                        :multiple="false"
                        :editable="ticket.is_anonymized !== 1 && $store.getters.permissions.includes('ticket-section-information-edit')"
                      ></select-field>
                      <div class="form-group row" v-if="ticket.is_anonymized !== 1">
                          <label class="col-form-label col-sm-4">  {{ $t('labels.notes-hate-crime') }}</label>
                          <button
                            v-tooltip="this.$t('labels.edit-note')"
                            v-if="hateCrimeMode === 'view'"
                            type="button"
                            class="btn"
                            v-on:click="toggleHateCrimeMode"
                            >
                            <i class="fas fa-pen"></i>
                          </button>
                          <button
                            v-tooltip="this.$t('labels.close-note')"
                            v-if="hateCrimeMode === 'edit'"
                            type="button"
                            class="btn"
                            v-on:click="toggleHateCrimeMode"
                            >
                            <i class="fas fa-check"></i>
                          </button>
                      </div>
                        <div v-if="hateCrimeMode === 'edit'">
                          <textarea-field
                            @toggleMode="toggleHateCrimeMode"
                            placeholder="Skriv något här…"
                            :model="ticket.crimerelation_tickets.find((crime) => crime.crimerelation.id == 1)"
                            api="updateCrimeRelationTicketField"
                            field="hate_crime_motive_comment"
                            label=""
                            >
                          </textarea-field>
                        </div>
                        <span v-if="hateCrimeMode === 'view'" class="pre-space">{{ ticket.crimerelation_tickets.find((crime) => crime.crimerelation.id == 1).hate_crime_motive_comment }}</span>
                    </div>

                    <div v-if="$store.getters.permissions.includes('complement-questions') && this.ticket.risk_id == true">
                      <h5 class="bb-1 pb-3 mb-3 mt-5" style="font-size: 1rem;">{{ $t('labels.extra-info-increased-risk') }} <info-box field="Ärendeformuläret - flik Om ärendet, Extra info förhöjd risk" /></h5>
                      
                      <select-field
                        api="updateTicketField"
                        :model="ticket"
                        :options="ticketFieldOptions.risk_assessments"
                        db_field="riskAssessments"
                        field="risk_assessments"
                        :label="this.$t('labels.risk-assessment')"
                        :optionLabel="this.$label()"
                        :noDrop="true"
                        :multiple="true"
                        :editable="ticket.is_anonymized !== 1 && $store.getters.permissions.includes('ticket-section-information-edit')"
                      ></select-field>
                      
                        <select-field
                            api="updateTicketField"
                            :model="ticket"
                            :options="ticketFieldOptions.protected_personal_data"
                            field="protected_personal_data"
                            db_field="protected_personal_data_id"
                            :label="this.$t('labels.protected-personal-data')"
                            :optionLabel="this.$label()"
                            :noDrop="true"
                            :multiple="false"
                            :clearable="true"
                            :editable="ticket.is_anonymized !== 1 && $store.getters.permissions.includes('ticket-section-information-edit')"
                            :infoBox="'Ärendeformuläret - flik Om ärendet, Extra info VINR-Skyddade personuppgifter'"
                        
                          ></select-field>

                      <div class="form-group row" v-if="ticket.is_anonymized !== 1">
                          <label class="col-form-label col-sm-4">  {{ $t('labels.notes-increased-risk') }}</label>
                          <button
                            v-tooltip="this.$t('labels.edit-note')"
                            v-if="assessmentMode === 'view'"
                            type="button"
                            class="btn"
                            v-on:click="toggleAssessmentMode"
                            >
                            <i class="fas fa-pen"></i>
                          </button>
                          <button
                            v-tooltip="this.$t('labels.close-note')"
                            v-if="assessmentMode === 'edit'"
                            type="button"
                            class="btn"
                            v-on:click="toggleAssessmentMode"
                            >
                            <i class="fas fa-check"></i>
                          </button>
                      </div>
                        <div v-if="assessmentMode === 'edit'">
                          <textarea-field
                            @toggleMode="toggleAssessmentMode"
                            placeholder="Skriv något här…"
                            :model="ticket"
                            api="updateTicketField"
                            field="risk_assessment_comment"
                            label=""
                            >
                          </textarea-field>
                        </div>
                        <span v-if="assessmentMode === 'view'" class="pre-space">{{ ticket.risk_assessment_comment }}</span>
                    </div>
                
                    <div v-if="$store.getters.permissions.includes('complement-questions') && ticket.crimerelation_tickets.find((crime) => [3, 9, 10, 11].includes(crime.crimerelation.id))">

            
                      <h5 class="bb-1 pb-3 mb-3 mt-5" style="font-size: 1rem;">{{ $t('labels.extra-info-vinr') }} <info-box field="Ärendeformuläret - flik Om ärendet, Extra info VINR" /></h5>

                      <checkbox
                        :model="ticket"
                        api="updateTicketField"
                        field="children"
                        :label="this.$t('labels.children-at-home-under-18')"
                        :editable="ticket.is_anonymized !== 1 && $store.getters.permissions.includes('ticket-section-information-edit')"
                      ></checkbox>

                      <checkbox
                        :model="ticket"
                        api="updateTicketField"
                        field="pets"
                        :label="this.$t('labels.pets')"
                        :editable="ticket.is_anonymized !== 1 && $store.getters.permissions.includes('ticket-section-information-edit')"
                      ></checkbox>

                      

                      <select-field
                        api="updateTicketField"
                        :model="ticket"
                        :options="ticketFieldOptions.restraining_orders"
                        field="restraining_order"
                        db_field="restraining_order_id"
                        :label="this.$t('labels.restraining-order')"
                        :optionLabel="this.$label()"
                        :noDrop="true"
                        :clearable="true"
                        :multiple="false"
                        :editable="ticket.is_anonymized !== 1 && $store.getters.permissions.includes('ticket-section-information-edit')"
                        :infoBox="'Ärendeformuläret - flik Om ärendet, Extra info VINR-Kontaktförbud'"
                      ></select-field>

                      <select-field
                        api="updateTicketField"
                        :model="ticket"
                        :options="ticketFieldOptions.shelters"
                        field="shelter"
                        db_field="shelter_id"
                        :label="this.$t('labels.protected-residence')"
                        :optionLabel="this.$label()"
                        :noDrop="true"
                        :clearable="true"
                        :multiple="false"
                        :editable="ticket.is_anonymized !== 1 && $store.getters.permissions.includes('ticket-section-information-edit')"
                      ></select-field>

                      <select-field
                        api="updateTicketField"
                        :model="ticket"
                        :options="ticketFieldOptions.coroner_reports"
                        db_field="coronerReports"
                        field="coroner_reports"
                        :label="this.$t('labels.coroner-report')"
                        :optionLabel="this.$label()"
                        :noDrop="true"
                        :multiple="true"
                        :editable="ticket.is_anonymized !== 1 && $store.getters.permissions.includes('ticket-section-information-edit')"
                      ></select-field>

                      <div class="form-group row" v-if="ticket.is_anonymized !== 1">
                          <label class="col-form-label col-sm-4">  {{ $t('labels.notes-vinr') }}</label>
                          <button
                            v-tooltip="this.$t('labels.edit-note')"
                            v-if="vinrMode === 'view'"
                            type="button"
                            class="btn"
                            v-on:click="toggleVinrMode"
                            >
                            <i class="fas fa-pen"></i>
                          </button>
                          <button
                            v-tooltip="this.$t('labels.close-note')"
                            v-if="vinrMode === 'edit'"
                            type="button"
                            class="btn"
                            v-on:click="toggleVinrMode"
                            >
                            <i class="fas fa-check"></i>
                          </button>
                      </div>
                      <div v-if="vinrMode === 'edit'">
                        <textarea-field
                          @toggleMode="toggleVinrMode"
                          placeholder="Skriv något här…"
                          :model="ticket"
                          api="updateTicketField"
                          field="vinr_comment"
                          label=""
                          >
                        </textarea-field>
                      </div>
                      <span v-if="vinrMode === 'view'" class="pre-space">{{ ticket.vinr_comment }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="widget-body bt-1-dark pr-0 pl-3" v-if="showDetails && showDetailSection === 6">
          <div class="container-fluid">
            <div class="row">
              <div class="col-12 br-1">
                <crime
                    :label_boj="this.$t('labels.primary-crime-type-boj')"
                    :label_police="this.$t('labels.primary-crime-type-police')"
                    :infoBox_boj="'Ärendeformuläret - flik Primär brottstyp, BOJbrottstyp'"
                    :infoBox_police_primary="'Ärendeformuläret - flik Primär brottstyp, PolisBrottstyp'"
                    :infoBox_police_secondary="'Ärendeformuläret - flik Primär brottstyp, PolisBrottstyp (dropdown)'"
                    :editable="$store.getters.permissions.includes('ticket-section-primary-crime-edit')"
                    :ticket="ticket"
                    :options="ticketFieldOptions"
                    :crime="ticket.crime"
                    crime_type="primary"
                    @updateTicket="getTicket"
                    @fieldSavedWithData="setAutomaticFields"
                    @updateCompleted="handleTicketUpdate"
                ></crime>
              </div>
            </div>
          </div>
        </div>
        <div class="widget-body bt-1 pr-0 pl-3" v-if="showDetails && showDetailSection === 2">
          <div class="container-fluid">
            <div class="row">
              <div class="col-4 br-1">
                <h5 class="bb-1 pb-3 mb-3" style="font-size: 1rem;">{{ $t('labels.name-gender-age') }}</h5>

                <text-field
                    :editable="$store.getters.permissions.includes('ticket-section-person-edit')"
                    v-if="ticket.is_anonymized !== 1"
                    :model="ticket.person"
                    api="updateTicketPersonField"
                    field="first_name"
                    :label="this.$t('labels.first-name')"
                ></text-field>
                <text-field
                    :editable="$store.getters.permissions.includes('ticket-section-person-edit')"
                    v-if="ticket.is_anonymized !== 1"
                    :model="ticket.person"
                    api="updateTicketPersonField"
                    field="first_names"
                    :label="this.$t('labels.all-first-names')"
                ></text-field>
                <text-field
                    :editable="$store.getters.permissions.includes('ticket-section-person-edit')"
                    v-if="ticket.is_anonymized !== 1"
                    :model="ticket.person"
                    api="updateTicketPersonField"
                    field="last_name"
                    :label="this.$t('labels.last-name')"
                ></text-field>

                <select-field
                    api="updateTicketField"
                    :clearable="false"
                    :model="ticket"
                    :multiple="false"
                    :options="ticketFieldOptions.gender"
                    db_field="gender_id"
                    field="gender"
                    :infoBox="'Ärendeformuläret - flik Om stödsökanden, Kön'"
                    :label="this.$t('labels.gender')"
                    :optionLabel="this.$label()"
                    :editable="ticket.is_anonymized !== 1 && $store.getters.permissions.includes('ticket-section-person-edit')"
                    @fieldSaved="getTicket"
                ></select-field>

                <select-field
                    api="updateTicketField"
                    :clearable="false"
                    :model="ticket"
                    :multiple="false"
                    :options="ticketFieldOptions.ages"
                    db_field="age_id"
                    field="age"
                    :infoBox="'Ärendeformuläret - flik Om stödsökanden, Åldersgrupp'"
                    :label="this.$t('labels.age-group')"
                    :optionLabel="this.$label()"
                    :editable="ticket.is_anonymized !== 1 && $store.getters.permissions.includes('ticket-section-person-edit')"
                    @fieldSaved="updateAgeFields"
                    @fieldSavedWithData="setAutomaticFields"
                ></select-field>

                <text-field
                    :editable="$store.getters.permissions.includes('ticket-section-person-edit')"
                    v-if="ticket.is_anonymized !== 1"
                    :model="ticket.person"
                    api="updateTicketPersonField"
                    field="birth_year"
                    :infoBox="'Ärendeformuläret - flik Om stödsökanden, Födelseår'"
                    :label="this.$t('labels.birth-year')"
                    :onKeyPress="onlyNumbers"
                    @fieldSaved="updateAge"
                ></text-field>

                <text-field
                    :model="ticket"
                    api="updateTicketField"
                    field="current_age"
                    :editable="ticket.is_anonymized !== 1 && $store.getters.permissions.includes('ticket-section-person-edit')"
                    :label="this.$t('labels.exact-age')"
                    :onKeyPress="onlyNumbers"
                    @fieldSaved="setBirthYearField"
                >
                </text-field>

                <select-field
                    api="updateTicketField"
                    :model="ticket"
                    :options="ticketFieldOptions.language"
                    db_field="language_id"
                    field="language"
                    :infoBox="'Ärendeformuläret - flik Om stödsökanden, Språk'"
                    :label="this.$t('labels.language')"
                    :optionLabel="this.$label()"
                    :editable="ticket.is_anonymized !== 1 && $store.getters.permissions.includes('ticket-section-person-edit')"
                    @fieldSaved="getTicket"
                ></select-field>

                <div v-if="$store.getters.permissions.includes('complement-questions') && [2,3].includes(ticket.age_id)">
                    <h5 class="bb-1 pb-3 mb-3 mt-5" style="font-size: 1rem;">{{ $t('labels.extra-info-under-18') }} <info-box field="Ärendeformuläret - flik Om stödsökanden, Extra info under 18" /></h5>
                    <checkbox
                      :editable="ticket.is_anonymized !== 1 && $store.getters.permissions.includes('ticket-section-information-edit')"
                      :model="ticket"
                      api="updateTicketField"
                      field="family_violence"
                      :label="this.$t('labels.witnessed-violence-between-relatives')"
                      text="Ja"
                    ></checkbox>
                </div>

                <div v-if="$store.getters.permissions.includes('complement-questions') && ticket.consent_fields && ticket.consent_fields.find((consent) => consent.id == 2 && consent.checked == 1) && $store.getters.permissions.includes('ticket-section-person-edit')">
                  <h5 class="bb-1 pb-3 mb-3 mt-5" style="font-size: 1rem;">{{ $t('labels.extra-info-disability') }} <info-box field="Ärendeformuläret - flik Om stödsökanden, Extra info funktionsnedsättning" /></h5>
                  <select-field
                      api="updateTicketField"
                      :model="ticket"
                      :options="ticketFieldOptions.disabilities"
                      :editable="ticket.is_anonymized !== 1 && $store.getters.permissions.includes('ticket-section-person-edit')"
                      db_field="disability_id"
                      field="disability"
                      :label="this.$t('labels.disability')"
                      :optionLabel="this.$label()"
                      :multiple="false"
                      :clearable="true"
                  ></select-field>
                  <div class="form-group row" v-if="ticket.is_anonymized !== 1">
                      <label class="col-form-label col-sm-4">  {{ $t('labels.notes-disability') }}</label>
                      <button
                        v-tooltip="this.$t('labels.edit-note')"
                        v-if="disabilityMode === 'view'"
                        type="button"
                        class="btn"
                        v-on:click="toggleDisabilityMode"
                        >
                        <i class="fas fa-pen"></i>
                      </button>
                      <button
                        v-tooltip="this.$t('labels.close-note')"
                        v-if="disabilityMode === 'edit'"
                        type="button"
                        class="btn"
                        v-on:click="toggleDisabilityMode"
                        >
                        <i class="fas fa-check"></i>
                      </button>
                  </div>
                    <div v-if="disabilityMode === 'edit'">
                      <textarea-field
                        @toggleMode="toggleDisabilityMode"
                        placeholder="Skriv något här…"
                        :model="ticket"
                        api="updateTicketField"
                        field="disability_comment"
                        label=""
                        >
                      </textarea-field>
                    </div>
                    <span v-if="disabilityMode === 'view'" class="pre-space">{{ ticket.disability_comment }}</span>
                </div>
              </div>
              <div class="col-4 br-1" v-if="ticket.is_anonymized !== 1">
                <h5 class="bb-1 pb-3 mb-3" style="font-size: 1rem;">{{ $t('labels.contact-information') }}</h5>

                <text-field
                    v-if="ticket.is_anonymized !== 1"
                    :model="ticket.person"
                    :editable="$store.getters.permissions.includes('ticket-section-person-edit')"
                    api="updateTicketPersonField"
                    field="email"
                    :label="this.$t('labels.email-one')"
                    type="email"
                ></text-field>
                <text-field
                    v-if="ticket.is_anonymized !== 1"
                    :editable="$store.getters.permissions.includes('ticket-section-person-edit')"
                    :model="ticket.person"
                    api="updateTicketPersonField"
                    field="email_two"
                    :label="this.$t('labels.email-two')"
                    type="email"
                ></text-field>

                <text-field
                    v-if="ticket.is_anonymized !== 1"
                    :model="ticket.person"
                    :editable="$store.getters.permissions.includes('ticket-section-person-edit')"
                    api="updateTicketPersonField"
                    field="phone"
                    :label="this.$t('labels.phone-home')"
                    type="tel"
                    :onKeyPress="onlyPhoneNumbers"
                ></text-field>

                <text-field
                    v-if="ticket.is_anonymized !== 1"
                    :editable="$store.getters.permissions.includes('ticket-section-person-edit')"
                    :model="ticket.person"
                    api="updateTicketPersonField"
                    field="phone_work"
                    :label="this.$t('labels.phone-work')"
                    type="tel"
                    :onKeyPress="onlyPhoneNumbers"
                ></text-field>

                <text-field
                    v-if="ticket.is_anonymized !== 1"
                    :editable="$store.getters.permissions.includes('ticket-section-person-edit')"
                    :model="ticket.person"
                    api="updateTicketPersonField"
                    field="mobile"
                    :label="this.$t('labels.mobile')"
                    type="tel"
                    :onKeyPress="onlyPhoneNumbers"
                ></text-field>
              </div>
              <div class="col-4 br-1" v-if="ticket.is_anonymized !== 1">
                <h5 class="bb-1 pb-3 mb-3" style="font-size: 1rem;">{{ $t('labels.address') }}</h5>

                <text-field
                    v-if="ticket.is_anonymized !== 1"
                    :editable="$store.getters.permissions.includes('ticket-section-person-edit')"
                    :model="ticket.person"
                    api="updateTicketPersonField"
                    field="co_address"
                    :label="this.$t('labels.possible-co-address')"
                ></text-field>

                <text-field
                    v-if="ticket.is_anonymized !== 1"
                    :editable="$store.getters.permissions.includes('ticket-section-person-edit')"
                    :model="ticket.person"
                    api="updateTicketPersonField"
                    field="streetname"
                    :label="this.$t('labels.address')"
                ></text-field>

                <text-field
                    v-if="ticket.is_anonymized !== 1"
                    :editable="$store.getters.permissions.includes('ticket-section-person-edit')"
                    :model="ticket.person"
                    api="updateTicketPersonField"
                    field="postal_code"
                    :label="this.$t('labels.postal-code')"
                ></text-field>

                <text-field
                    v-if="ticket.is_anonymized !== 1"
                    :editable="$store.getters.permissions.includes('ticket-section-person-edit')"
                    :model="ticket.person"
                    api="updateTicketPersonField"
                    field="postal_area"
                    :label="this.$t('labels.postal-area')"
                ></text-field>

                <text-field
                    v-if="ticket.is_anonymized !== 1"
                    :editable="$store.getters.permissions.includes('ticket-section-person-edit')"
                    :model="ticket.person"
                    api="updateTicketPersonField"
                    field="country"
                    :label="this.$t('labels.country')"
                ></text-field>
              </div>
            </div>
          </div>
        </div>
        <div class="widget-body bt-1 pr-0 pl-3" v-if="showDetails && showDetailSection === 3">
          <div class="container-fluid">
            <div class="row">
              <div class="col-12 br-1" v-for="crime in ticket.secondary_crimes" :key="crime.id">
                <crime
                    :label_boj="$t('labels.secondary-crime-type-boj')"
                    :label_police="$t('labels.secondary-crime-type-police')"
                    :editable="$store.getters.permissions.includes('ticket-section-primary-crime-edit')"
                    :ticket="ticket"
                    :options="ticketFieldOptions"
                    :crime="crime"
                    crime_type="secondary"
                    @updateTicket="getTicket"
                    @fieldSavedWithData="setAutomaticFields"
                ></crime>
              </div>

              <div class="row"
                   v-if="ticket.is_anonymized !== 1 && $store.getters.permissions.includes('ticket-section-secondary-crime-edit')">
                <div
                    class="col-12 bt-1 text-center d-flex align-content-center justify-content-center flex-wrap pt-5 pb-5"
                    v-for="n in (4 - ticket.secondary_crimes.length)"
                    :key="n"
                >
                  <div v-if="ticket.secondary_crimes.length !== 4 && n === 1">
                    <button v-if="!isAddingSecondaryCrime" class="btn btn-success btn-sm" @click="addSecondaryCrime">
                      <i class="fas fa-plus mr-1"></i> {{ $t('labels.add-crime') }}
                    </button>
                    <button v-if="isAddingSecondaryCrime" class="btn btn-success btn-sm">
                      <i class="fas fa-circle-notch fa-spin"></i>
                    </button>
                  </div>
                  <div class="text-muted" v-if="n !== 1">{{ $t('labels.place-additional-crime') }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="widget-body bt-1 pr-0 pl-3" v-if="showDetails && showDetailSection === 5">
          <div class="container-fluid">
            <div class="row">
              <div class="col-12 col-md-4 br-1">
                <h5 class="bb-1 pb-3 mb-3" style="font-size: 1rem;">{{ $t('labels.boj') }}</h5>

                <div class="form-group row" v-if="ticket.local_office">
                  <label for="ticket_police_county" class="col-sm-4 col-form-label">{{ $t('labels.victim-support-centre') }}</label>
                  <div class="col-sm-8 pt-2">
                    {{ ticket.local_office.office.label }}
                  </div>
                </div>
                <div class="form-group row" v-if="ticket.local_office">
                  <label class="col-sm-4 col-form-label">{{ $t('labels.local-office') }}</label>
                  <div class="col-sm-5 pt-2">
                    {{ ticket.local_office.label }}
                  </div>
                </div>

                <v-guard :permissions="['ticket-field-localoffice-edit']">
                  <div class="form-group row" v-if="ticket.local_office">
                    <label class="col-sm-4 col-form-label"></label>
                    <div class="col-sm-5" v-if="!isLoading && ticket.is_anonymized !== 1">
                      <button @click="toggleSelectLocalOffice" class="btn btn-success">{{ $t('labels.change-local-office') }}</button>
                      <info-box :field="'Ärendeformuläret - flik Geografi, knapp byt lokalkontor'" />
                    </div>
                  </div>
                </v-guard>
              </div>
              <div class="col-12 col-md-4 br-1">
                <h5 class="bb-1 pb-3 mb-3" style="font-size: 1rem;">{{ $t('labels.geography') }}</h5>

                <select-field
                    v-if="ticket.county"
                    api="updateTicketField"
                    :model="ticket"
                    :options="ticket.municipality_list"
                    db_field="municipality_id"
                    field="municipality"
                    :infoBox="'Ärendeformuläret - flik Geografi, rubrik Kommun'"
                    :label="this.$t('labels.municipality')"
                    :editable="ticket.is_anonymized !== 1 && $store.getters.permissions.includes('ticket-section-geography-edit')"
                    @fieldSaved="getTicket"
                    @fieldSavedWithData="setAutomaticFields"
                ></select-field>
                <div class="form-group row">
                  <label class="col-sm-4 col-form-label">{{ $t('labels.police-region') }}</label>
                  <div class="col-sm-8 pt-2">
                    {{ ticket.police_region.label }}
                  </div>
                </div>
                <div class="form-group row" v-if="ticket.police_region">
                  <label class="col-sm-4 col-form-label">{{ $t('labels.county') }}</label>
                  <div class="col-sm-8 pt-2">
                    {{ ticket.county.label }}
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-4 br-1">
                <h5 class="bb-1 pb-3 mb-3" style="font-size: 1rem;">{{ $t('labels.locally') }}</h5>

                <select-field
                    v-if="ticket.county && postal.length !== 0"
                    api="updateTicketField"
                    :model="ticket"
                    :options="postal"
                    db_field="postal_code_value_id"
                    field="postal_code_value"
                    :label="this.$t('labels.postal-code')"
                    :clearable="true"
                    optionLabel="postal_code"
                    :editable="ticket.is_anonymized !== 1 && $store.getters.permissions.includes('ticket-section-geography-edit')"
                    @fieldSaved="updatePostalTown"
                ></select-field>

                <div class="form-group row" v-if="postal.length == 0">
                  <label class="col-4">{{ $t('labels.postal-code') }}</label>
                  <div class="col-sm-8 text-muted">
                    {{ $t('labels.not-available') }}
                  </div>
                </div>

                <select-field
                    v-if="ticket.county && postalUniqueNames.length !== 0"
                    api="updateTicketField"
                    :model="ticket"
                    :options="postalUniqueNames"
                    db_field="postal_code_id"
                    field="postal_code"
                    :label="this.$t('labels.postal-area')"
                    :clearable="true"
                    optionLabel="post_town"
                    :editable="ticket.is_anonymized !== 1 && $store.getters.permissions.includes('ticket-section-geography-edit')"
                ></select-field>

                <div class="form-group row" v-if="postalUniqueNames.length == 0">
                  <label class="col-4">{{ $t('labels.postal-area') }}</label>
                  <div class="col-sm-8 text-muted">
                    {{ $t('labels.not-available') }}
                  </div>
                </div>

                <select-field
                    v-if="ticket.county && districts.length !== 0"
                    api="updateTicketField"
                    :model="ticket"
                    :options="districts"
                    db_field="district_id"
                    field="district"
                    :label="this.$t('labels.district')"
                    :clearable="true"
                    :editable="ticket.is_anonymized !== 1 && $store.getters.permissions.includes('ticket-section-geography-edit')"
                ></select-field>

                <div class="form-group row" v-if="districts.length === 0">
                  <label class="col-4">{{ $t('labels.district') }}</label>
                  <div class="col-sm-8 text-muted">
                    {{ $t('labels.not-available') }}
                  </div>
                </div>

                <select-field
                    v-if="ticket.county && exposedAreas.length !== 0"
                    api="updateTicketField"
                    :model="ticket"
                    :options="exposedAreas"
                    db_field="exposed_area_id"
                    field="exposed_area"
                    :label="this.$t('labels.exposed-areas')"
                    :clearable="true"
                    :editable="ticket.is_anonymized !== 1 && $store.getters.permissions.includes('ticket-section-geography-edit')"
                ></select-field>

                <select-field
                  v-if="$store.getters.permissions.includes('ticket-section-geography-court-edit')"
                  :editable="ticket.is_anonymized !== 1"
                  api="updateTicketField"
                  :clearable="true"
                  :model="ticket"
                  :options="ticketFieldOptions.courts"
                  db_field="court_id"
                  field="court"
                  :label="this.$t('labels.court')"
                  :save-manually="true"
                  @change="setCourtDate"
                  @fieldSaved="getTicket"
                  :infoBox="'Ärendeformuläret - flik Geografi, rubrik domstol'"
                ></select-field>

                <date-field
                  v-if="ticket.is_anonymized !== 1 && ticket.court && $store.getters.permissions.includes('ticket-section-geography-court-edit')"
                  :model="ticket"
                  api="updateTicketField"
                  field="case_date"
                  :label="this.$t('labels.case-date')"
                  :infoBox="'Ärendeformuläret - flik Geografi, rubrik Rättegångsdatum'"
                ></date-field>
                
                <checkbox
                  v-if="ticket.court && ticket.case_date.startDate"
                  :editable="ticket.is_anonymized !== 1"
                  :model="ticket"
                  api="updateTicketField"
                  field="case_online"
                  :label="this.$t('labels.on-link')"
                  :text="this.$t('labels.yes')"
                ></checkbox>

              </div>
            </div>
          </div>
        </div>
        <div class="widget-body bt-1 pr-0 pl-3" v-if="showDetails && showDetailSection === 7">
          <div class="col-5">
            <h5 class="bb-1 pb-3 mb-3" style="font-size: 1rem;">{{ $t('labels.consent') }}</h5>
            <div
                v-if="consentFieldWait"
                style="
								width: 100%;
								height: 100%;
								text-align: center;
								padding-top: 30%;
								font-size: 20px;
								background-color: #fff;
								opacity: 0.6;
								z-index: 100;
							"
                class="position-absolute"
            >
              <i class="fas fa-circle-notch fa-spin"></i>
            </div>
          </div>
          <div class="pr-0 pl-3 text-left ">
            <div class="row mb-2 text-left align-items-center" v-for="informationField in ticket.consent_fields" :key="informationField.id">
              <div class="col-3">
                {{ $to(informationField, 'label') }} <info-box v-if="informationField.info_box" :field="informationField.info_box.field" />
              </div>
              <div class="col-2">
                  <div class="row align-items-center text-left widget-header border-0" >
                    <div class="col-4 widget-header-toolbar"> 
                      <a v-tooltip="$t('labels.request-a-response-by-email')" v-if="ticket.is_anonymized == 0 && $store.getters.permissions.includes('ticket-section-consent-edit') && (informationField.permission !== null && $store.getters.permissions.includes(informationField.permission.name)) && !informationField.checked" class="btn btn-btn btn-no-outline-white" @click="updateConsentEmail(ticket, informationField, $event)"><i class="far fa-envelope"></i></a>
                    </div>
                    <div class="col-4 widget-header-toolbar">
                      <a v-tooltip="$t('labels.request-a-response-by-sms')" v-if="ticket.is_anonymized == 0 && $store.getters.permissions.includes('ticket-section-consent-edit') && (informationField.permission !== null && $store.getters.permissions.includes(informationField.permission.name)) && !informationField.checked" class="btn btn-btn btn-no-outline-white" @click="updateConsentSms(ticket, informationField, $event)"><i class="fas fa-mobile-alt"></i></a>
                    </div>
                    <div class="col-4">
                      <input
                          type="checkbox"
                          class="mt-1 form-check"
                          v-model="informationField.checked"
                          :disabled="ticket.is_anonymized == 1 || !$store.getters.permissions.includes('ticket-section-consent-edit') || (informationField.permission !== null && !$store.getters.permissions.includes(informationField.permission.name)) || (informationField.checked && ['email', 'sms', 'form'].includes(informationField.delivery_method) && !$store.getters.permissions.includes('ticket-access-all-tickets'))"
                          @change="toggleConsent(ticket, informationField, $event)"
                      />
                    </div>
                 </div>
              </div>
              <div class="col-2">
                <input
                    type="date"
                    class="form-control fixed-padding"
                    v-if="informationField.checked"
                    @change="updateConsentField(ticket, informationField, $event)"
                    v-model="informationField.date"
                    :disabled="ticket.is_anonymized == 1 || !$store.getters.permissions.includes('ticket-section-consent-edit') || (informationField.permission !== null && !$store.getters.permissions.includes(informationField.permission.name)) || (informationField.checked && ['email', 'sms', 'form'].includes(informationField.delivery_method) && !$store.getters.permissions.includes('ticket-access-all-tickets'))"
                />
              </div>
              <div class="col-5" v-if="informationField.checked">
                <div v-if="informationField.user">
                  {{ informationField.user.name }}
                </div>
                <div  v-if="informationField.delivery_method == 'email'">
                  {{ $t('labels.confirmed-by-email') }}
                </div>
                <div v-if="informationField.delivery_method == 'sms'">
                  {{ $t('labels.confirmed-by-sms') }}
                </div>
                <div v-if="informationField.delivery_method == 'form'">
                    {{ $t('labels.confirmed-by-form') }}
                  <!-- <i class="fas fa-clipboard-check"></i> -->
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="widget-body bt-1 pr-0 pl-3" v-if="showDetails && showDetailSection === 8 && surveyAssessment">
          <div class="col-5 br-1">
            <div class="bb-1 pb-3 mb-0" v-if="surveyAssessment.answered_at">
              <h5 class=" " style="font-size: 1rem;">{{ surveyAssessment.title }} </h5><small class="mt-0 mb-0">({{ surveyAssessment.answered_at }})</small>
            </div>
            <div class="row mb-4">
              <div class="col-12" v-if="surveyAssessment.answered_at">
                <div class="" v-for="question in surveyAssessment.questions" :key="question.id">
                  <div class="mt-3">
                      <h5 class="mb-1" style="font-size: 1rem;"><b>{{ question.label }}</b></h5>
                  </div>
                  <div class="mb-3">
                      {{ question.type == 'yes_no' ? question.response == '1' ? $t('labels.yes') : $t('labels.no') : question.response }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="widget-body bt-1 pr-0 pl-3" v-if="showDetails && showDetailSection === 9 && surveyInterview">
          <div v-if="!ticket.interview_completed_at && ticket.is_anonymized !== 1" class="container-fluid">
            <div class="row">
              <div class="col-12 br-1">
                <h5 class="bb-1 pb-3 mb-3" style="font-size: 1rem;">{{ $t('labels.information') }}</h5>

                <button type="button" class="btn btn-light mr-5" v-on:click="sendCalendarInvite()"> <i class="fas fa-user-circle"></i>
                  {{ $t('labels.send-calendar-invite') }}
                </button>

                <a :href="surveyInterview.redirect_url + '?interviewer_id=' + user.user.id" target="_blank"><button class="btn btn-system-blue">
                  <i class="fas fa-user"></i> {{ $t('labels.start-interview') }}
                </button></a>

                <a v-if="!surveyAssessment  || !surveyAssessment.answered_at" @click="createAssessmentSurvey"><button class="btn btn-system-blue ml-5">
                  <i class="fas fa-user" :class="{'fa-spin': isLoadingAssessmentSurvey}"></i> {{ $t('labels.user-survey') }}
                </button></a>

                <button class="btn btn-danger ml-5" @click="anonymizeTicket">
                  <i class="fas fa-archive"></i> {{ $t('labels.anonymize-ticket') }}
                </button>

                 <a id="openInNewTabWithoutWarning" style="opacity: 0; width:0; height: 0; position: relative; z-index: 0;" ref="openInNewTabWithoutWarning" target="_blank" >asdf</a>
              </div>
            </div>
          </div>
          <div class="col-5 br-1">
            <div class="bb-1 pb-3 mb-0" v-if="surveyInterview.answered_at">
              <h5 class=" " style="font-size: 1rem;">{{ surveyInterview.title }} </h5><small class="mt-0 mb-0">({{ surveyInterview.answered_at }})</small>
            </div>
            <div class="row mb-4">
              <div class="col-12" v-if="surveyInterview.answered_at">
                <div class="" v-for="question in surveyInterview.questions" :key="question.id">
                  <div class="mt-3">
                      <h5 class="mb-1" style="font-size: 1rem;"><b>{{ question.label }}</b></h5>
                  </div>
                  <div class="mb-3">
                      {{ question.response }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="widget-body bt-1 pr-0 pl-3" v-if="showDetails && showDetailSection === 4">
          <div class="container-fluid">
            <div class="row">
              <div class="col-12 col-md-6 br-1">
                <div class="d-flex justify-content-between bb-1 pb-3 mb-3 align-items-center">
                  <h5 class=" pb-0 mb-0 " style="font-size: 1rem;">{{ $t('labels.information') }} </h5>
                  <router-link target="_blank" v-if="ticket.police_import_ticket && $store.getters.permissions.includes('section-access-police-import')" :to="{ name: 'police-import-ticket', params: { id: ticket.police_import_ticket.id  } }" class="breadcrumbs-link"><small class="pb-0 mb-0">	 ImportID #{{ ticket.police_import_ticket.id }}</small></router-link>
                </div>
               
                <text-field
                    :editable="ticket.is_anonymized !== 1 && $store.getters.permissions.includes('ticket-section-police-edit')"
                    :model="ticket"
                    api="updateTicketField"
                    field="police_case_number"
                    :label="this.$t('labels.k-number')"
                ></text-field>

                <div style="position:relative">
                  <select-field
                      v-if="$store.getters.permissions.includes('ticket-view-police-import-fields')"
                      :options="ticketFieldOptions.police_support_org"
                      db_field="police_support_org"
                      field="police_support_org"
                      :label="this.$t('labels.support-org')"
                      :optionLabel="this.$label()"
                      api="updateTicketField"
                      :model="ticket"
                      :infoBox="'Stödorg'"
                      :editable="ticket.is_anonymized !== 1 && $store.getters.permissions.includes('ticket-edit-police-import-fields')"
                      @fieldSaved="getTicket"
                      @fieldSavedWithData="setAutomaticFields"
                  ></select-field>

                  <div style="position:absolute;top:10px;left:33.3333333333%;margin-left:20px;background:#fff;width: 50%">
                      {{ selectedSupportOrgLabel }}
                  </div>
                </div>

                <text-field
                    v-if="$store.getters.permissions.includes('ticket-view-police-import-fields')"
                    :editable="false"
                    :model="ticket"
                    api="updateTicketField"
                    field="police_support_org_raw"
                    :label="this.$t('labels.support-org-org')"
                ></text-field>

                <text-field
                    v-if="$store.getters.permissions.includes('ticket-view-police-import-fields')"
                    :editable="ticket.is_anonymized !== 1 && $store.getters.permissions.includes('ticket-edit-police-import-fields')"
                    :model="ticket"
                    api="updateTicketField"
                    field="police_support_org_text"
                    :infoBox="'Stödorg fritext'"
                    :label="this.$t('labels.support-org-text')"
                ></text-field>

                <text-field
                    :editable="ticket.is_anonymized !== 1 && $store.getters.permissions.includes('ticket-section-police-edit')"
                    :model="ticket"
                    api="updateTicketField"
                    field="police_unit"
                    :label="this.$t('labels.handling-unit')"
                ></text-field>

                <text-field
                    :editable="ticket.is_anonymized !== 1 && $store.getters.permissions.includes('ticket-section-police-edit')"
                    :model="ticket"
                    api="updateTicketField"
                    field="police_unit_secondary"
                    :label="this.$t('labels.handling-unit-shorten')"
                    :infoBox="''"
                ></text-field>

                <text-field
                    v-if="!ticket.is_anonymized"
                    :editable="ticket.is_anonymized !== 1 && $store.getters.permissions.includes('ticket-section-police-edit')"
                    :model="ticket"
                    api="updateTicketField"
                    field="police_responsible_person"
                    :label="this.$t('labels.administrator')"
                    :infoBox="''"
                ></text-field>
                <text-field
                    v-if="!ticket.is_anonymized"
                    :editable="ticket.is_anonymized !== 1 && $store.getters.permissions.includes('ticket-section-police-edit')"
                    :model="ticket"
                    api="updateTicketField"
                    field="police_recieved_by_person"
                    :infoBox="'Begäran om stöd'"
                    :label="this.$t('labels.request-for-support')"
                ></text-field>
                <text-field
                    :editable="ticket.is_anonymized !== 1 && $store.getters.permissions.includes('ticket-section-police-edit')"
                    :model="ticket"
                    api="updateTicketField"
                    field="police_reported_date"
                    :label="this.$t('labels.registration-date')"
                ></text-field>
                <text-field
                    :editable="ticket.is_anonymized !== 1 && $store.getters.permissions.includes('ticket-section-police-edit')"
                    :model="ticket"
                    api="updateTicketField"
                    field="police_reported_time"
                    :label="this.$t('labels.registration-time')"
                ></text-field>
              </div>
              <div class="col-12 col-md-6 br-1">
                <h5 class="bb-1 pb-3 mb-3" style="font-size: 1rem;">{{ $t('labels.police-comment') }}</h5>

                <text-field
                    :editable="ticket.is_anonymized !== 1 && $store.getters.permissions.includes('ticket-section-police-edit')"
                    :model="ticket"
                    api="updateTicketField"
                    field="police_comments"
                    label=""
                    :rows="13"
                ></text-field>
              </div>
            </div>
          </div>
        </div>
        <div class="widget-footer with-tabs" v-if="!showDetails">
          <ul class="nav nav-tabs nav-tabs-line" role="tablist">
            <li class="nav-item">
              <router-link class="nav-link" :to="{ name: 'ticket', params: { id: ticket.id } }">{{ $t('labels.overview') }}</router-link>
            </li>
            <li class="nav-item d-none" v-if="ticket.is_anonymized !== 1">
              <router-link class="nav-link" :to="{ name: 'ticket.history', params: { id: ticket.id } }">
                {{ $t('labels.history') }}
              </router-link>
            </li>
            <v-guard :permissions="['ticket-related-view']">
              <li class="nav-item" v-if="ticket.is_anonymized !== 1">
                <router-link class="nav-link" :to="{ name: 'ticket.related', params: { id: ticket.id } }">
                  {{ $t('labels.related-tickets') }}
                </router-link>
              </li>
            </v-guard>
            <v-guard
                :permissions="[
								'ticket-section-person-view',
								'ticket-section-information-view',
								'ticket-section-primary-crime-view',
								'ticket-section-secondary-crime-view',
								'ticket-section-geography-view',
								'ticket-section-consent-view',
								'ticket-section-police-view',
							]"
            >
              <li class="nav-item ml-auto mr-0">
                <div class="nav-link" @click="toggleDetails()" v-if="showDetails">{{ $t('labels.close') }} <i
                    class="ml-2 fas fa-angle-up"></i></div>
                <div class="nav-link" @click="toggleDetails()" v-if="!showDetails && ticket.is_anonymized !== 1">
                  {{ $t('labels.edit-ticket') }} <i class="ml-2 fas fa-angle-down"></i>
                </div>
                <div class="nav-link" @click="toggleDetails()" v-if="!showDetails && ticket.is_anonymized === 1">
                  {{ $t('labels.more-information') }} <i class="ml-2 fas fa-angle-down"></i>
                </div>
              </li>
            </v-guard>
          </ul>
        </div>
      </div>

      <div style="position: relative;">
        <transition name="slide-right">
          <router-view
              @get-ticket="getTicket"
              class="child-view"
              v-if="!isLoading"
              :render="reRender"
              :ticket="ticket"
              :key="ticket.id"
          ></router-view>
        </transition>
      </div>
    </div>
  </layout-main>
</template>

<script>
import BojAPI from '@/api/boj';
import LayoutMain from '@/views/Layouts/Main';
import Crime from '@/components/ticket/Crime';
import SelectLocalOffice from '@/components/ticket/SelectLocalOffice';
import SelectField from '@/components/ticket/fields/SelectField';
import TextField from '@/components/ticket/fields/TextField';
import Checkbox from '@/components/ticket/fields/Checkbox';
import MessageFromAPI from "@/components/ticket/MessageFromAPI";
import InfoBox from '@/components/InfoBox';
import InfoBoxSidebar from '@/components/InfoBoxSidebar';
import _ from 'lodash';
import axios from 'axios'
import { Trans } from '@/plugins/Translation';
import DateField from '@/components/ticket/fields/DateField';
import TextareaField from '@/components/ticket/fields/TextareaField';
import moment from 'moment';
import SendMailBasic from '@/components/forms/SendMailBasic';

export default {
  name: 'ticket-page',
  components: {
    LayoutMain,
    MessageFromAPI,
    Crime,
    SelectLocalOffice,
    SelectField,
    TextField,
    Checkbox,
    InfoBox,
    InfoBoxSidebar,
    Trans,
    DateField,
    TextareaField,
    SendMailBasic
  },
  props: {
    id: Number,
  },
  data() {
    return {
      sendMail: false,
      hateCrimeMode: 'view',
      assessmentMode: 'view',
      vinrMode: 'view',
      disabilityMode: 'view',
      moment: moment,
      messagesFromApi: false,
      consentFieldWait: false,
      isAddingSecondaryCrime: false,
      selectLocalOffice: false,
      confirmDelete: '',
      showDetails: false,
      showDetailSection: 2,
      searchFieldOptions: '',
      reRender: false,
      isSaving: false,
      isLoading: true,
      isFetchingTicket: false,
      ticket: {},
      showSidebar: false,
      ticketFieldOptions: [],
      isLoadingAssessmentSurvey: false,
      edit: {
        data: [],
        value: 'label',
        field: '',
        multiple: false,
        selectedValues: [],
        title: '',
      },
      surveyInterview: {},
      surveyAssessment: {},
      findAutoFields: false,
      allAutomaticRisk: [],
      allAutomaticPrio: [],
      automaticFields: [
        'crime.crime',
        'secondary_crimes',
        'crimerelation',
        'age',
        'category',
        'municipality'
      ]
    };
  },

  computed: {
    selectedSupportOrgLabel() {
        const options = this.ticketFieldOptions.police_support_org;
        if (!options) return null;

        const optionArray = typeof options === 'function' ? options() : options;
        const selectedOption = optionArray.find(
            (option) => option.id == this.ticket.police_support_org
        );

        return selectedOption ? selectedOption.label : this.ticket.police_support_org_label;
    },
    isRisk() {
      return this.ticket.risk_id != 0 && this.ticket.risk_id != null;
    },
    postal() {
      return this.$store.getters.getPostalByMunicipality(this.ticket.municipality_id);
    },

    postalUniqueNames() {
      const postalData = this.$store.getters.getPostalByMunicipality(this.ticket.municipality_id);
      let names = postalData.filter((obj, pos, arr) => {
        return arr.map((mapObj) => mapObj['post_town']).indexOf(obj['post_town']) === pos;
      });

      return names.sort((a, b) => a.post_town.localeCompare(b.post_town));
    },

    districts() {
      return this.$store.getters.getDistrictsByMunicipality(this.ticket.municipality_id);
    },
    exposedAreas() {
      return this.$store.getters.getExposedAreasByMunicipality(this.ticket.municipality_id);
    },
    user() {
      return this.$store.getters.user;
    },
  },

  beforeRouteEnter(to, from, next) {
    BojAPI.getTicket(to.params.id)
        .then((response) => {
          next((vm) => {
            vm.setData(response.data)
            .then(response => response && vm.getAutomticRisks())
          });
        })
        .catch((error) => {
        });
  },
  beforeRouteUpdate(to, from, next) {
    this.supplier = null;
    BojAPI.getTicket(to.params.id).then((response) => {
      this.setData(response.data);
      next();
    });
  },
  mounted() {
    this.getTicketOptions();
  },
  watch: {
    findAutoFields(val, old) {
      if (val) {
        this.getTicket();
        this.findAutoFields = false;
      }
    },

    allAutomaticRisk(val, old) {
      if (!val.length && old.length == 1) {
        this.ticket.risk_id = 0;

        this.showNotice(this.$t('labels.increased-risk-removed-automatically'));
        BojAPI.updateTicketField(this.ticket, 'risk_id', this.ticket.risk_id)
      }
    },

    allAutomaticPrio(val, old) {
      if (!val.length && old.length == 1) {
        this.ticket.priority = this.ticketFieldOptions.priority.find(val => val.id === 2)

        this.showNotice(this.$t('labels.priority-automatically-back-to-label', {label: this.ticket.priority.label}));
        BojAPI.updateTicketField(this.ticket, 'priority_id', this.ticket.priority.id)
      }
    }
  },

  methods: {
    closeSendMail() {
      this.sendMail = false;
    },
    openSendMail() {
      this.sendMail = true;

      setTimeout(() => {
        const bodyField = document.getElementById('send-mail-basic-body');
        const subjectField = document.getElementById('send-mail-basic-subject');

        BojAPI.getExternalEmail(this.id).then((response) => {
          bodyField.value = response.data.content
          subjectField.value = response.data.title
        }).catch((error) => {
          alert(error);
        });
      }, 200)
      
    },
    doSendMail(payload) {
      BojAPI.sendBulkMailES(this.latestQuery, payload.subject, payload.body, payload.signature, 'external_notification', payload.to_email, this.ticket.id).then((response) => {
        this.$notify({
          group: 'foo',
          text: this.$t('labels.mail-sent'),
          type: 'success',
          duration: 3000,
        });
      });
    },
    async sendExternalEmail() {
      try {
        BojAPI.getExternalEmail(this.id).then((response) => {
          console.log('response');
          console.log(response.data);
          const mailtoLink = `mailto:?subject=${encodeURIComponent(
            response.data.title
          )}&body=${encodeURIComponent(response.data.content)}`;

          window.location.href = mailtoLink

        }).catch((error) => {
          alert(error);
        });

        /*const mailtoLink = `mailto:${this.email}?subject=${encodeURIComponent(
          this.subject
        )}&body=${encodeURIComponent(content)}`;

        window.location.href = mailtoLink;*/
      } catch (error) {
        console.error('Error fetching email content:', error);
        alert('Failed to fetch email content.');
      }
    },
    toggleHateCrimeMode() {
      if (this.hateCrimeMode === 'view') {
        this.hateCrimeMode = 'edit';
      } else {
        this.hateCrimeMode = 'view';
      }
    },
    toggleAssessmentMode() {
      if (this.assessmentMode === 'view') {
        this.assessmentMode = 'edit';
      } else {
        this.assessmentMode = 'view';
      }
    },
    toggleVinrMode() {
      if (this.vinrMode === 'view') {
        this.vinrMode = 'edit';
      } else {
        this.vinrMode = 'view';
      }
    },
    toggleDisabilityMode() {
      if (this.disabilityMode === 'view') {
        this.disabilityMode = 'edit';
      } else {
        this.disabilityMode = 'view';
      }
    },
    /**
     * Display Error Message
     */
    showError(value) {
      this.$notify({
        group: 'foo',
        text: value,
        type: 'warning',
        duration: 3000,
      });
    },
    /**
     * Display Confirmation Message
     */
    showConfirmation(value) {
      this.$notify({
        group: 'foo',
        text: value,
        type: 'success',
        duration: 3000,
      });
    },

    /**
     * Display Notice Message
     */
    showNotice(value) {
      this.$notify({
        group: 'foo',
        text: value,
        type: 'warn',
        duration: 3000,
      });
    },

    onlyNumbers(event) {
      let keyPressed = event.key;
      if(keyPressed) {
        if (!keyPressed.match(/^\d$/)) {
          event.preventDefault();
        }
      }
    },

    onlyPhoneNumbers(event) {
      // TODO Use a validation/formatting lib instead of hacking a solution.
      let keyPressed = event.key;
      let keyPrevious = event.srcElement.value[event.srcElement.value.length - 1];

      if(keyPressed) {
        if (!keyPressed.match(/^\d|\s|\+|-$/)) {
          event.preventDefault();
        }

        if (keyPrevious) {
          if (keyPrevious.match(/\s|\+|-$/) && keyPressed.match(/\s|\+|-$/)) {
            event.preventDefault();
          }
        }
      }
    },

    sendCalendarInvite() {
      BojAPI.sendCalendarInvite(this.ticket.person.id, 'person', this.ticket.id).then((response) => {
        this.showConfirmation('Kalenderinbjudan skickad');
        this.getTicket();
      })
        .catch((error) => {
          this.showError(error.response.data.error);
        });
    },

    showErrorActionModal(payload) {
      this.messagesFromApi = payload;
    },

    newTicketFromTicket() {
      BojAPI.newTicketFromTicket(this.ticket).then((response) => {
        this.$router.push({
          name: 'ticket',
          params: {id: response.data.id},
        });
      });
    },
    closeSelectOffice(local_office_id, local_office_label) {
      if (local_office_id) {

        this.$confirm({
          title: this.$t('labels.confirm-move'),
          message: this.$t('labels.confirm-move-ticket', {local_office_label: local_office_label}),
          button: {
            yes: this.$t('labels.ok'),
            no: this.$t('labels.cancel'),
          },
          callback: (confirm) => {

            if (confirm) {
              BojAPI.updateTicketField(this.ticket, 'local_office_id', local_office_id)
              .then((response) => {
                this.showSidebar = false;
                this.isSaving = false;

                this.showConfirmation(this.$t('labels.changes-saved'));

                this.$confirm({
                  title: this.$t('labels.done')+'!!',
                  message: this.$t('labels.ticket-moved', {id: this.id, local_office_label: local_office_label}),
                  button: {
                    yes: this.$t('labels.ok'),
                  },
                  callback: async () => {
                    await this.$router.push('/tickets');
                  }
                })

              })
              .catch((error) => {
                this.isSaving = false;
                this.getTicket();
                this.showError(this.$t('labels.no-permission'));
              });

            } else {
              this.selectLocalOffice = false;
            }
          },
        });


      }
      this.selectLocalOffice = false;
    },

    setAutomaticFields(data) {
      const risk_id = this.automaticRiskPropExists(data)
      const priority_id = this.automaticPrioPropExists(data)
      
      this.updateSuspectedPerpetrator(data);


      if (risk_id) {
        this.ticket.risk_id = 1;

        this.showNotice(this.$t('labels.increased-risk-set-automatically'));

        BojAPI.updateTicketField(this.ticket, 'risk_id', this.ticket.risk_id)
        .then(() => {
          this.findAutoFields = true;
        })
      }

      if (priority_id) {
        this.ticket.priority = this.ticketFieldOptions.priority.find(val => val.id === priority_id)
        this.ticket.priority_id = priority_id;

        this.showNotice(this.$t('labels.priority-automatically-to-label', {label: this.ticket.priority.label}));

        BojAPI.updateTicketField(this.ticket, 'priority_id', priority_id)
        .then(() => {
          this.findAutoFields = true;
        })
      }

      if (!risk_id && !priority_id) {
        this.getTicket();
      }

    },

    automaticRiskPropExists(data) {
      const clicked_automatic_risk = (data) => {
         if (typeof data.value === 'object' && data.value !== null) {
          if (Object.prototype.hasOwnProperty.call(data.value, 'automatic_risk') && data.value.automatic_risk) {
            return true
          } else {
            return false
          }
        } else {
          return false;
        }
      }

      if (data.key == 'municipality_id') {
        data.key = 'municipality';
      }

      if (this.existensOfProperty(data.key, 'automatic_risk', true)) {
        if (this.allAutomaticRisk.indexOf(data.key) === -1 ) {
          this.allAutomaticRisk.push(data.key)
        }
      } else {
        this.allAutomaticRisk = this.allAutomaticRisk.filter(item => item !== data.key)
      }

      return clicked_automatic_risk(data);
    },

    automaticPrioPropExists(data) {
      const clicked_automatic_prio = (data) => {
         if (typeof data.value === 'object' && data.value !== null) {
          if (Object.prototype.hasOwnProperty.call(data.value, 'priority_id') && data.value.priority_id) {
            return data.value.priority_id
          } else {
            return false
          }
        } else {
          return false;
        }
      }

      if (data.key == 'age_id') {
        data.key = 'age';
      }

      if (data.key == 'category_id') {
        data.key = 'category';
      }

      if (this.existensOfProperty(data.key, 'priority_id', true)) {
        if (this.allAutomaticPrio.indexOf(data.key) === -1 ) {
          this.allAutomaticPrio.push(data.key)
        }
      } else {
        this.allAutomaticPrio = this.allAutomaticPrio.filter(item => item !== data.key)
      }

      return clicked_automatic_prio(data);
    },

    async updateSuspectedPerpetrator(data) {
      if (data && data.key == 'crimerelation' && ['VINR-A', 'VINR-F', 'VINR-P', 'Hedersrelaterat'].includes(data.value.label)) {
         await BojAPI.updateTicketField(this.ticket, 'suspected_perpetrator_id', 1);
      }
    },

    getAutomticRisks() {
      this.automaticFields.forEach(field => {
        if (this.existensOfProperty(field, 'automatic_risk', true)) {
          if (this.allAutomaticRisk.indexOf(field) === -1 ) {
            this.allAutomaticRisk.push(field)
          }
        }

        if (this.existensOfProperty(field, 'priority_id', true)) {
          if (this.allAutomaticPrio.indexOf(field) === -1 ) {
            this.allAutomaticPrio.push(field)
          }
        }
      })
    },

    existensOfProperty(field, prop, value) {
      const customFilter = (obj, keyToFind) => {
        if (!obj || typeof obj !== 'object') {
          return [];
        }
        return Object.entries(obj)
          .reduce((acc, [key, value]) => (key === keyToFind)
            ? acc.concat(value)
            : (typeof value === 'object' && value)
            ? acc.concat(customFilter(value, keyToFind))
            : acc
          , [])
      }

      const getProp = (field) => {
        const fieldProp = field.split('.').reduce((r, e) => {
          return r[e];
        }, this.ticket)

        if (!Array.isArray(fieldProp)) {
          return [fieldProp]
        } else {
          return fieldProp
        }
      }

      return getProp(field).some(data => {
        return customFilter(data, prop).some(i => {
            if (i > 1) {
              i = 1;
            }

            return i == value
        } );
      })
    },

    toggleSelectLocalOffice() {
      this.selectLocalOffice = !this.selectLocalOffice;
    },

    /**
     * Submit request for ticket removal.
     */
    sendRemovalRequest() {
      BojAPI.sendRemovalRequest(this.ticket)
          .then(() => {
            this.confirmDelete = '';
            this.showConfirmation(this.$t('labels.removal-request-sent'));
            this.reRender = true;
          })
          .catch((error) => {
            this.showError(this.$t('labels.no-permission'));
          });
    },
    anonymizeTicket() {
      if (confirm('Är du säker?')) {
        BojAPI.anonymizeTicket(this.ticket.id)
          .then((response) => {
              let self = this;
              this.showConfirmation(this.$t('labels.ticket-anonymized'));
              setTimeout(function () {
                self.$router.go();
              }, 3000);
          })
          .catch((error) => {
              this.showError(this.$t('labels.no-permission'));
          });
      }
    },

    async setBirthYearField(value) {
      if (value !== '') {
        let age = new Date().getFullYear() - parseInt(value);

        this.ticket.person.birth_year = age;
        await this.setAgeGroupField(value);
        BojAPI.updateTicketPersonField(this.ticket.person, 'birth_year', age);
      }
    },

    async updateAge(value) {
      let age = new Date().getFullYear() - parseInt(value);

      this.ticket.current_age = age;
      await this.setAgeGroupField(age);
      BojAPI.updateTicketField(this.ticket, 'current_age', age);
    },

    async setAgeGroupField(age) {
      let currentAgeGroup = this.ticketFieldOptions.ages.find((item) => item.age_from <= age && item.age_to >= age);

      if(!currentAgeGroup) {
        return this.showError(this.$t('labels.error-age'));
      }

      this.ticket.age = currentAgeGroup;
      await BojAPI.updateTicketField(this.ticket, 'age_id', currentAgeGroup.id);
      this.setAutomaticFields({key: 'age', value: currentAgeGroup});
      
    },

    addHateCrime() {
        let hateCrime = this.ticketFieldOptions.crime_relation.find(val => val.id === 1);
        if (!this.ticket.crimerelation.find(e => e.id === hateCrime.id)) {
          this.ticket.crimerelation.push(hateCrime);
          BojAPI.updateTicketField(this.ticket, 'crimerelation', this.ticket.crimerelation);
          this.setAutomaticFields({key: 'crimerelation', value: hateCrime});
        }
    },

    setCourtDate(field, value) {
      if (this.ticket.case_date.startDate !== null) {
        this.updateCourtsField(field, value)
        return;
      }
      this.$confirm({
        message: "För att koppla ärendet till kommande rättegång vid viss domstol, invänta att kallelse sänts ut och ange rättegångsdatum.",
        button: {
          yes: 'OK',
        },
        callback: (confirm) => {
          this.ticket[field] = value
          this.$modal.show('show-court-date-modal');
        },
      });
    },

    updateCourtsField(field, value, multiple = false) {
      this.ticket[field] = value
      if (this.ticket[field] === null) {
        value = null;
      } else {
        if (multiple) {
          value = this.ticket[field];
        } else {
          value = this.ticket[field].id;
        }
      }
      BojAPI.updateTicketField(this.ticket, field, value)
      .then(() => {
        this.showConfirmation(this.$t('labels.changes-saved'));
        this.getTicket();
        if (!value) {
            Promise.all([
              BojAPI.updateTicketField(this.ticket, 'case_date', null),
              BojAPI.updateTicketField(this.ticket, 'case_online', null)
            ])
            .then(() => {
               this.ticket['case_date'] = {startDate: null, endDate: null};
               this.ticket['case_online'] = null;
              this.showConfirmation(this.$t('labels.changes-saved'));
            })
            .catch((error) => {
              this.showError(this.$t('labels.no-permission'));
            });
        }
      })
      .catch((error) => {
       this.showError(this.$t('labels.no-permission'));
      });
    },

    closeCaseDateModal() {
      if (this.ticket.case_date.startDate !== null) {
        this.updateCourtsField('court_id', this.ticket['court_id'])
      } else {
        this.ticket['court_id'] = null;
      }
    },

    updateTicketField(ticket, field) {
      var value = ticket[field];

      BojAPI.updateTicketField(this.ticket, field, value)
          .then(() => {
            this.showConfirmation(this.$t('labels.changes-saved'));
            this.showSidebar = false;
            this.isSaving = false;
            this.reRender = true;
          })
          .catch((error) => {
            this.isSaving = false;
            this.getTicket();
            this.showError(this.$t('labels.no-permission'));
          });
    },

    addSecondaryCrime() {
      this.isAddingSecondaryCrime = true;
      BojAPI.addSecondaryCrime(this.ticket.id)
          .then(() => {
            this.getTicket();
            this.$notify({
              group: 'foo',
              text: this.$t('labels.new-secondary-crime-added'),
              type: 'success',
              duration: 3000,
            });
            this.reRender = true;
          })
          .catch((error) => {
            this.getTicket();
            this.$notify({
              group: 'foo',
              text: this.$t('labels.no-permission'),
              type: 'warning',
              duration: 3000,
            });
          });
    },
    setShowDetailSection(section) {
      this.showDetailSection = section;
    },

    // TODO: Move to vuex?
    getTicketOptions: function () {
      BojAPI.getTicketOptions().then((response) => {
        this.ticketFieldOptions = response.data;
        console.log('response.data')
        console.log(response.data)
      });
    },


    toggleDetails() {
      if (this.showDetails) {
        this.showDetails = !this.showDetails;
        this.setShowDetailSection(2);
      } else {
        this.showDetails = !this.showDetails;
      }
    },

    /**
     * Enable filtering for values when sidebar is used.
     */
    filteredFieldOptions(field) {
      let search = this.searchFieldOptions.toLowerCase();
      if (search.length !== 0) {
        return _.filter(this.edit.data, function (obj) {
          return obj[field].toLowerCase().indexOf(search) !== -1;
        });
      }
      return this.edit.data;
    },

    /**
     * Toggle risk.
     *
     * TODO: Move to shared function.
     */
    toggleRisk() {
      if (this.ticket.risk_id === 0 || this.ticket.risk_id == null) {
        this.ticket.risk_id = 1;
      } else {
        this.ticket.risk_id = 0;
      }

      BojAPI.updateTicketField(this.ticket, 'risk_id', this.ticket.risk_id)
          .then(() => {
            this.showConfirmation(this.$t('labels.changes-saved'));
            this.getTicket();
            this.reRender = true;
          })
          .catch((error) => {
            this.getTicket();
            this.showError(this.$t('labels.no-permission-sending-ticket'));
          });
    },

    /**
     *  Send notification to all ticket users.
     */
    sendTicketNotification() {
      BojAPI.sendTicketNotification(this.ticket)
          .then(() => {
            this.showConfirmation(this.$t('labels.notification-sent'));
            this.getTicket();
          })
          .catch((error) => {
            this.showError(this.$t('labels.no-permission-sending-ticket'));
          });
    },

    sendTicketSpesPing() {
      BojAPI.sendTicketSpesPing(this.ticket)
          .then(() => {
            this.showConfirmation(this.$t('labels.notification-sent'));
            this.getTicket();
          })
          .catch((error) => {
            this.showError(this.$t('labels.no-permission-sending-ticket'));
          });
    },

    sendTicketOfficePing() {
      BojAPI.sendTicketOfficePing(this.ticket)
          .then(() => {
            this.showConfirmation(this.$t('labels.notification-sent'));
            this.getTicket();
          })
          .catch((error) => {
            this.showError(this.$t('labels.no-permission-sending-ticket'));
          });
    },

    /**
     *  Send notification to all ticket users.
     */
    sendTicketUpdateNotification() {
      BojAPI.sendTicketUpdateNotification(this.ticket)
          .then(() => {
            this.showConfirmation(this.$t('labels.notification-sent'));
            this.getTicket();
          })
          .catch((error) => {
            this.showError(this.$t('labels.no-permission-sending-ticket'));
          });
    },

    /**
     * Send ticket to local office.
     */
    sendTicket() {
      BojAPI.sendTicket(this.ticket)
          .then(() => {
            this.showConfirmation(this.$t('labels.ticket-sent'));
            this.getTicket();
          })
          .catch((error) => {
            this.showError(this.$t('labels.no-permission-sending-ticket'));
          });
    },
    updateConsentEmail(ticket, field, event) {

      this.consentFieldWait = true;
        field.user_id = this.user.user.id;
        BojAPI.updateTicketConsentEmail(ticket, field, event.target.value)
            .then((response) => {
              this.showConfirmation(this.$t('labels.email-sent'));
              this.consentFieldWait = false;
              this.setData(response.data);
            })
            .catch((error) => {
              this.consentFieldWait = false;
              this.showError(this.$t('labels.email-error'));
            });

    },
    updateConsentSms(ticket, field, event) {

      this.consentFieldWait = true;
        field.user_id = this.user.user.id;
        BojAPI.updateTicketConsentSms(ticket, field, event.target.value)
            .then((response) => {
              this.showConfirmation(this.$t('labels.sms-sent'));
              this.consentFieldWait = false;
              this.setData(response.data);
            })
            .catch((error) => {
              this.consentFieldWait = false;
              this.showError(this.$t('labels.sms-error'));
            });

    },
    updateConsentField(ticket, field, event) {
      this.consentFieldWait = true;
      if ((field.checked && field.foreign_id === null) || field.foreign_id !== null) {
        field.user_id = this.user.user.id;
        BojAPI.updateTicketConsentField(ticket, field, event.target.value)
            .then((response) => {
              this.showConfirmation(this.$t('labels.changes-saved'));
              this.consentFieldWait = false;
              this.setData(response.data);
              if(field.id == 1 && field.checked == true) {
                this.addHateCrime();
              }
            })
            .catch((error) => {
              this.consentFieldWait = false;
              this.showError(this.$t('labels.no-permission'));
            });
      }
    },
    toggleConsent(ticket, field, event) {
      if(!field.checked) {
        this.$confirm({
          message: `Du kryssar nu ur ett tidigare lämnat samtycke. Var noga med att ta bort eventuell information du matat in i systemet som inte längre får finnas där nu när samtycket återtagits.`,
          button: {
            no: 'Avbryt',
            yes: 'OK',
          },
          callback: (confirm) => {
            if (confirm) {
              this.updateConsentField(ticket, field, event);
            } else {
              field.checked = !field.checked;
            }
          },
        });
      } else {
        this.updateConsentField(ticket, field, event);
      }
    },

    /**
     * Assign received data to local state.
     */
    async setData(payload) {
      this.ticket = payload.data;
      this.isLoading = false;

      return true
    },

    handleTicketUpdate(data) {
      console.log('Ticket component received updateCompleted with:', data);
      this.setData(data);
    },

    /**
     * Retrieve ticket from API.
     */
    getTicket: _.debounce(function () {
      const axiosSource = axios.CancelToken.source();

      if (this.isFetchingTicket) {
        this.isFetchingTicket.cancel();
        this.isFetchingTicket = false;
      }

      this.isFetchingTicket = {cancel:axiosSource.cancel};

      BojAPI.getTicket(this.id, {cancelToken: axiosSource.token}).then((response) => {
        this.ticket = response.data.data;
        this.isLoading = false;
        this.isFetchingTicket = false;
        this.isAddingSecondaryCrime = false;
        this.surveyInterview = this.ticket.ticket_surveys.find(val => val.tag === 'interview');
        this.surveyAssessment = this.ticket.ticket_surveys.find(val => val.tag === 'assessment');

      }).catch((error) => {
        if(axios.isCancel(error)) {
          return
        }

        this.showError(this.$t('labels.ticket-error'));
      });
    }, 1000),

    /**
     *  Toggle sidebar
     *
     *  Reset search options before.
     */
    toggleSidebar() {
      this.searchFieldOptions = '';
      this.showSidebar = !this.showSidebar;
    },

    /**
     * Update postal town
     */
    updatePostalTown() {
      this.ticket.postal_code = this.ticket.postal_code_value;
    },

    /**
     *
     */
    updateAgeFields() {
      this.ticket.person.birth_year = '';
      this.ticket.current_age = '';
      BojAPI.updateTicketField(this.ticket, 'current_age', '');
      BojAPI.updateTicketPersonField(this.ticket.person, 'birth_year', '');
    },

    /**
     * Update field on ticket resource.
     */
    updateField(field, change_age_field = true) {
      this.isSaving = true;
      let value = '';
      let fieldname = field;

      // Sync postal town when selecting a postal number.
      if (field === 'postal_code_value_id') {
        this.ticket.postal_code = this.ticket.postal_code_value;
      }

      // Sync age crime when changing age.
      if (field == 'age_id' && change_age_field) {
        this.updateAgeFields();
      }

      // We're dealing with a relationship field.
      if (fieldname.endsWith('_id')) {
        fieldname = fieldname.substring(0, fieldname.length - 3);
        if (this.ticket[fieldname] !== null) {
          value = this.ticket[fieldname].id;
        } else {
          value = null;
        }
      } else {
        value = this.ticket[fieldname];
      }

      BojAPI.updateTicketField(this.ticket, field, value)
          .then(() => {
            this.showConfirmation(this.$t('labels.changes-saved'));
            this.showSidebar = false;
            this.isSaving = false;
            this.getTicket();
            this.reRender = true;
          })
          .catch((error) => {
            this.isSaving = false;
            this.getTicket();
            this.showError(this.$t('labels.no-permission'));
          });
    },

    /**
     * Sets a field with the selected value
     */
    setField(value) {
      this.isSaving = true;
      this.searchFieldOptions = '';



      // if no multiple values
      if (this.edit.multiple === false) {
        BojAPI.updateTicketField(this.ticket, this.edit.field, value.id)
            .then(() => {
              this.showConfirmation(this.$t('labels.changes-saved'));
              this.getTicket();
              this.showSidebar = false;
              this.ticket[this.edit.field] = value;
              this.isSaving = false;
              this.reRender = true;
            })
            .catch(async(error) => {

              if (error.response) {

                // Handle error messages from server
                if (error.response.status === 409) {
                  this.isSaving = false;
                  this.showSidebar = false;
                  this.showErrorActionModal(error.response.data);
                } else if (error.response.status === 406) {
                  this.isSaving = false;
                  this.showSidebar = false;
                  let errorCount = error.response.data.length;
                  let index = 0;
                  for (let value of error.response.data) {
                    var confirm = await this.showConfirmationDialog(value)
                    if (confirm) {
                      // B, Right Button
                      if (value.tag == 'crimerelation') {
                        this.showDetails = true;
                        this.setShowDetailSection(1);
                        break;
                      }
                    } else {
                      // A, Left Button
                        if (value.tag == 'municipality') {
                          this.showDetails = true;
                          this.setShowDetailSection(5)
                          break;
                        }
                    }
                    index++;
                  }

                  if(index == errorCount) {
                    let timestamp = +new Date();
                    BojAPI.updateTicketField(this.ticket, 'ticketstatus_id_override_' + timestamp, 5).then(() => {
                      this.showConfirmation(this.$t('labels.changes-saved'));
                      this.getTicket();
                      this.showSidebar = false;
                      this.ticket[this.edit.field] = value;
                      this.isSaving = false;
                      this.reRender = true;
                    })
                  }
                } else {
                  this.isSaving = false;
                  this.getTicket();
                  this.showError(this.$t('labels.no-permission'));
                }
              }
            });
      } else {
        if (!this.edit.selectedValues.includes(value.id)) {
          this.ticket[this.edit.field].push(value);
          this.edit.selectedValues.push(value.id);
        } else {
          this.ticket[this.edit.field] = this.ticket[this.edit.field].filter((item) => item.id !== value.id);
          this.edit.selectedValues = this.edit.selectedValues.filter((item) => item !== value.id);
        }
      }
    },

    async showConfirmationDialog(e) {
      return new Promise((resolve, reject) => {
        this.$confirm({
          message: this.$t('labels.ticket-confirm-close-' + e.tag),
          button: {
            no: this.$t('labels.confirm-a-' + e.tag),
            yes: this.$t('labels.confirm-b-' + e.tag),
          },
          callback: (confirm) => {
            resolve(confirm);
          },
        });
        setTimeout(() => { document.querySelector('.vc-container').style.width = '775px'; }, 50);
      });
    },

    async createAssessmentSurvey() {
      this.isLoadingAssessmentSurvey = true;
      let response = await BojAPI.createSurvey(this.ticket, 'assessment');
      this.isLoadingAssessmentSurvey = false;
      let ticketSurvey = response.data;
      if(ticketSurvey) {
        this.$refs.openInNewTabWithoutWarning.href = ticketSurvey.redirect_url;
        this.$refs.openInNewTabWithoutWarning.click();
      } else {
        this.showError(this.$t('labels.no-matching-survey-found-error'));
      }
    },

    /**
     * Edit the value of a field.
     *
     * Shows the sidebar, populating the this.edit object with the selected crime properties.
     *
     * @param field
     * @param method
     * @param multiple
     * @param value
     * @param title
     */
    editField(field, method, multiple, value, title) {
      this.edit.field = field;
      this.edit.value = value;
      this.edit.multiple = multiple;
      this.edit.title = title;

      // Assign the already selected ids to an array
      if (this.ticket[field] !== null) {
        if (multiple) {
          this.edit.selectedValues = _.map(this.ticket[field], 'id');
        } else {
          this.edit.selectedValues = [this.ticket[field].id];
        }
      } else {
        this.edit.selectedValues = [];
      }

      // Retrieve the available options for the field
      BojAPI[method]()
          .then((response) => {
            this.edit.data = response.data.data;
            this.showSidebar = true;
          })
          .catch((error) => {
            this.showError(this.$t('labels.no-data-from-api'));
          });
    },
  },
};
</script>
